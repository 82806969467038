<template>
    <v-container fluid>
		<v-row justify="center" align="start" >
			<v-col cols="12" sm="8">
                <v-row class="ma-0 mb-4" justify="center" align="center" :class="!affichagePortable ? 'mt-6':''">
                    <v-avatar color="blue-grey lighten-3" size="100">
                        <img v-if="utilisateurCourant && utilisateurCourant.photo" :src="getPhoto(utilisateurCourant.photo)">
                        <span v-else class="white--text text-h3 font-weight-light">{{utilisateurCourant != null ? premiereLettreMaj(utilisateurCourant.prenom)+premiereLettreMaj(utilisateurCourant.nom): ''}}</span>
                    </v-avatar>
                    <v-col cols="auto" class="pl-4">
                        <v-row class="ma-0" align="center">
                            <v-avatar class="mr-4" v-if="utilisateurCourant && utilisateurCourant.grade" tile color="blue-grey lighten-3" size="30">
                                <img :src="getPhoto(utilisateurCourant.grade.photo)">
                            </v-avatar>
                            <div class="text-h5 font-weight-light primary--text">{{nomUtilisateur}}</div>
                        </v-row>
                        <v-row class="ma-0">
                            <div class="text-h6 font-weight-light font-italic primary--text">{{nomCentre}}</div>
                        </v-row>
                        <v-row class="ma-0">
                            <div class="text-body-2 orange--text">{{nomEquipe}}</div>
                        </v-row>
                        <v-row class="ma-0">
                            <div class="text-body-2 primary--text">{{donneesCompte ? donneesCompte.telephone : ''}}</div>
                        </v-row>
                        <v-row class="ma-0">
                            <div class="text-body-2 primary--text">{{donneesCompte ? donneesCompte.email : ''}}</div>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <div class="primary--text text-h6 font-weight-light mt-2">Paramètres</div>
                <v-row justify="center" align="center">
                    <div class="liste_parametres">Coordonnées privées</div><v-switch class="ml-6" v-model="donneesCompte.coordonnees_privees" inset @change="onChangeCoordPrivees" :loading="chargementCoordPrivees"></v-switch>
                </v-row>
                <v-row justify="center" align="center">
                    <div class="liste_parametres">Recevoir les emails</div><v-switch class="ml-6" v-model="donneesCompte.reception_email" inset @change="onChangeAutorisationMail" :loading="chargementAutorisationMail"></v-switch>
                </v-row>
                <v-row justify="center" align="center">
                    <div class="liste_parametres">Lier un agenda Google</div><v-switch class="ml-6" v-model="activation_agenda_google" inset @change="onChangeActivationGoogle" :loading="chargementAgendaGoogle"></v-switch>
                </v-row>
                <v-row justify="center" align="center" class="mt-2" v-if="gestion_agatt">
                    <div style="width: 300px; word-wrap:break-word">Lien calendrier AGATT :
                        <div class="caption" >{{donneesCompte.lien_agatt ? donneesCompte.lien_agatt : 'non renseigné'}}</div>
                    </div>
                </v-row>
                <!-- <v-row justify="center" align="center">
                    <div class="liste_parametres">Afficher les notifications</div><v-switch class="ml-6" v-model="notificationsActivees" inset @change="onChangeAutorisationNotifications" :loading="chargementAutorisationNotification" :disabled="!notificationSupportees"></v-switch>
                </v-row> -->
                <v-row justify="center" class="mt-4">
                    <v-col cols="12" md="7">
                        <v-btn outlined color="primary" class="primary--text" block @click.prevent="onClickUpdateCoord">
                            <v-icon class="mr-2">mdi-pencil</v-icon>
                            Modifier les infos personnelles
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="7">
                        <v-btn outlined color="primary" class="primary--text" block @click.prevent="openDialogModifPassword = true">
                            <v-icon class="mr-2">mdi-lock-open-outline</v-icon>
                            Changer le mot de passe
                        </v-btn> 
                    </v-col>
                </v-row>               
			</v-col>
		</v-row>
       
        <!-- dialogue edit infos personnelles -->
        <v-dialog v-model="openDialogEditCoordonnees" persistent max-width="500px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Modifier les infos personnelles</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                
                 <v-card-text>
                    <v-form class="mt-6">
                        <v-row class="ma-0">
                            <v-text-field label="Téléphone" type="text" v-model.trim="formulaireEditCoordonnees.telephone"></v-text-field>
                        </v-row>
                        <v-row class="ma-0">
                            <v-text-field label="Mail" type="text" v-model.trim="formulaireEditCoordonnees.mail"></v-text-field>
                        </v-row>
                        <v-row class="ma-0" v-if="gestion_agatt">
                            <v-text-field label="Lien AGATT" type="text" v-model.trim="formulaireEditCoordonnees.lien_agatt"></v-text-field>
                        </v-row>
                        <v-row class="ma-0" align="center">
                            <v-col class="pa-0" cols="1">
                                <v-checkbox v-model="formulaireEditCoordonnees.acceptation_politique"></v-checkbox>
                            </v-col>
                            <v-col class="pa-0" :class="affichagePortable ? 'pl-4': ''" cols="11">
                                <span>J'ai lu et accepte la </span><span class="primary--text font-weight-bold text-decoration-underline mx-1" style="cursor: pointer" @click="onClickPolitiqueConfidentialite"> politique de confidentialité</span><span> du site.</span>
                            </v-col>
                        </v-row>       
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleUpdateCoordonnees">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="updateCoordonnees" :disabled="!formulaireEditCoordonnees.acceptation_politique" :loading="chargementEditCoordonnees">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>

        <!-- dialogue changement du mot de passe -->
        <v-dialog v-model="openDialogModifPassword" persistent max-width="500px">
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Modifier le mot de passe</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-form>
                        <v-text-field label="Nouveau mot de passe" type="password" hint="minimum 6 caractères" v-model.trim="$v.formulaireUpdatePassword.nouveau_pass.$model" :error-messages="erreursPassword"></v-text-field>
                        <v-text-field label="Confirmation du nouveau mot de passe" type="password" v-model.trim="$v.formulaireUpdatePassword.nouveau_pass_confirmation.$model" :error-messages="erreursPasswordConfirm"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="OnClickAnnulModifPassword">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="updatePassword" :loading="chargementModifPassword" :disabled="estFormulaireModifPasswordValid">OK</v-btn>  
                </v-card-actions>
            </v-card>
        </v-dialog>     
	</v-container>
</template>

<script>
    import axios from 'axios'
    import { required, sameAs, minLength } from 'vuelidate/lib/validators'
	export default {
        created() {
            this.$store.commit('majTitrePage', 'Compte')
        },
        mounted() {
            this.$store.dispatch('getDonneesCompte')
            this.surveilleEnregistrementGoogle()
        },
        data() {
			return {
                activation_agenda_google: false,
                openDialogEditCoordonnees: false,
                openDialogModifPassword: false,
                chargementAgendaGoogle: false,
                chargementEditCoordonnees: false,
                chargementAutorisationMail: false,
                chargementCoordPrivees: false,
                chargementAutorisationNotification: false,
                chargementModifPassword: false,
                formulaireEditCoordonnees: {
                    telephone: '',
                    mail: '',
                    lien_agatt: null,
                    acceptation_politique: false,
                },
                formulaireUpdatePassword: {
                    nouveau_pass: '',
                    nouveau_pass_confirmation: ''                    
                },
            }
		},
		computed: {
            affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
            donneesCompte() {
                return this.$store.getters.donneesCompte
            },
            erreursPassword() {
                const errors = []
                if (!this.$v.formulaireUpdatePassword.nouveau_pass.$dirty) return errors
                !this.$v.formulaireUpdatePassword.nouveau_pass.required && errors.push('Le mot de passe est obligatoire')
                return errors
            },
            erreursPasswordConfirm() {
                const errors = []
                if (!this.$v.formulaireUpdatePassword.nouveau_pass_confirmation.$dirty) return errors
                !this.$v.formulaireUpdatePassword.nouveau_pass_confirmation.required && errors.push('Le mot de passe est obligatoire')
                if(this.formulaireUpdatePassword.nouveau_pass_confirmation != this.formulaireUpdatePassword.nouveau_pass) errors.push('Les mots de passe ne correspondent pas')
                return errors
            },
            estFormulaireModifPasswordValid() {
                return this.$v.formulaireUpdatePassword.$invalid
            },
            gestion_agatt() {
				//	renvoie true si le centre est géré avec agatt
				return this.$store.getters.membreCourant.centre.parametres.includes("gestion agatt")
			},
            nomCentre() {
                return this.$store.getters.membreCourant.centre.nom
            },
            nomEquipe() {
                return this.$store.getters.membreCourant.equipe ? this.$store.getters.membreCourant.equipe.nom : ''
            },
            nomUtilisateur() {
                return this.$store.getters.utilisateurCourant ? this.$store.getters.utilisateurCourant.prenom + ' ' + this.$store.getters.utilisateurCourant.nom : ''
            },
            utilisateurCourant(){
                return this.$store.getters.utilisateurCourant
            },
		},
		methods: {
            getPhoto(chemin){
                return "/storage/"+chemin;
            },
            onChangeActivationGoogle() {
                //this.chargementAgendaGoogle = true

                //  si activation
                if(this.activation_agenda_google) {
                    this.$gapi.getGapiClient().then((gapi) => {
                        gapi.auth2.getAuthInstance().signIn().catch((error) => {
                            this.activation_agenda_google = false
                        })
                    
                    })
                    
                }
                //  si désactivation
                else {
                    this.$gapi.getGapiClient().then((gapi) => {
                       
                       gapi.auth2.getAuthInstance().signOut().catch((error) => {
                            this.activation_agenda_google = true
                        })
                    
                    })
                }
                
            },
            onChangeAutorisationMail() {
                this.chargementAutorisationMail = true
                axios.post('api/user/autor_email', { nouvel_etat: this.donneesCompte.reception_email})
                    .then( (reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getDonneesCompte')
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors de la mise à jour')
                    })
                    .then(() => {
                        this.chargementAutorisationMail = false
                    })
            },
            OnClickAnnulModifPassword() {
                this.$v.formulaireUpdatePassword.$reset()
                this.formulaireUpdatePassword.nouveau_pass = ''
                this.formulaireUpdatePassword.nouveau_pass_confirmation = ''
                this.openDialogModifPassword = false
            },
            onClickAnnuleUpdateCoordonnees() {
                this.openDialogEditCoordonnees = false
                this.formulaireEditCoordonnees.telephone = ''
                this.formulaireEditCoordonnees.mail = ''
                this.formulaireEditCoordonnees.acceptation_politique = false  
            },
            onClickPolitiqueConfidentialite() {
                this.$router.push('/politique')
            },
            onClickUpdateCoord() {
                //  remplissage du formulaire
                this.formulaireEditCoordonnees.telephone = this.donneesCompte.telephone
                this.formulaireEditCoordonnees.mail = this.donneesCompte.email
                this.formulaireEditCoordonnees.lien_agatt = this.donneesCompte.lien_agatt
                this.formulaireEditCoordonnees.acceptation_politique = false
                this.openDialogEditCoordonnees = true
            },
            onChangeCoordPrivees() {
                this.chargementCoordPrivees = true
                axios.post('api/user/coord_privees', { nouvel_etat: this.donneesCompte.coordonnees_privees})
                    .then( (reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getDonneesCompte')
                       
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors de la mise à jour')
                    })
                    .then(() => {
                        this.chargementCoordPrivees = false
                    })
            },
            premiereLettreMaj(text){
                return text.charAt(0).toUpperCase();
            },
            snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
            },
            surveilleEnregistrementGoogle() {
                // Listen for sign-in state changes.
                this.$gapi.getGapiClient().then((gapi) => {
                    // On surveille les changements d'enregistrement google calendar
					gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)

					//	on vérifie l'enregistrement actuel
					this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
                    
                })
            },
            updateCoordonnees() {
                this.chargementEditCoordonnees = true
                
                axios.post('api/user/update_coord', { id: this.$store.getters.utilisateurCourant.id, ...this.formulaireEditCoordonnees })
                    .then( (reponse) => {
                        reponse.data.resultat && this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getDonneesCompte')
                    })
                    .catch( (error) => {
                        this.snackbar('error', 'Erreur lors de la mise à jour');
                    })
                    .then(() => {
                        this.chargementEditCoordonnees = false
                        this.onClickAnnuleUpdateCoordonnees()
                        this.openDialogEditCoordonnees = false
                    })
            },
            updatePassword() {
                //  on vérifie que le formulaire est valide
                if( !this.$v.formulaireUpdatePassword.$invalid) {
                    this.chargementModifPassword = true
                    axios.post('api/user/change_password', { id: this.$store.getters.utilisateurCourant.id, ...this.formulaireUpdatePassword})
                        .then((reponse) => {
                            //  on n'affiche la snackbar que l'il y a un message à afficher
                            reponse.data.resultat && this.snackbar('success',reponse.data.resultat);
                            
                        })
                        .catch((error) => {
                            //  on n'affiche la snackbar que s'il y a un message à afficher
                            error.response.data.error && this.snackbar('error', error.response.data.error);
                            if(error.response.data.code_erreur != 1) this.openDialogModifPassword = false
                        })
                        .then(() => {
                            this.chargementModifPassword = false
                            this.$v.formulaireUpdatePassword.$reset()
                            this.formulaireUpdatePassword.nouveau_pass = ''
                            this.formulaireUpdatePassword.nouveau_pass_confirmation = ''
                            this.openDialogModifPassword = false
                        })
                
                }
            },
            updateSigninStatus(isSignedIn) {
                this.activation_agenda_google = isSignedIn
            }
        },
        validations: {
            formulaireUpdatePassword: {
                nouveau_pass: {
                    required,
                    minLength: minLength(6)                    
                },
                nouveau_pass_confirmation: {
                    required,
                    sameAsPassword: sameAs('nouveau_pass')                    
                },
            },
        }
  	}
</script>

<style>

.liste_parametres {
    min-width: 200px;
}
</style>
